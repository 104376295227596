import React from 'react'

import { useState, useEffect } from 'react'
// import Banner from './Banner.js'

import { Link, useNavigate } from 'react-router-dom'

// import Logo from '../assets/LOGO_crop.png'
import Logo from '../assets/Juicy-Logo-anim-TransparentBackgound-White-HD.gif'
import aboutImage from '../assets/image2.jpeg'

// const bottom = 'bottom'
// const top = 'top'


function Home() {

  const navigate = useNavigate()
  const [exiting, setExiting] = useState(false)
  const [arrowVisible, setArrowVisible] = useState(true)
  const iconSize = 25

  useEffect(() => {
    const onScroll = () => setArrowVisible(false);
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, [arrowVisible]);

  function delayRoute(e, path) {
    e.preventDefault()
    setExiting(true)
    setTimeout(() => navigate(path), 600)
  }

  return (
    <>

      <div style={{ display: "flex" }}>
        <svg className={`down-arrow`} fill="currentColor" height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 330 330" style={{ rotate: "90deg", position: "absolute", height: "20px", width: "auto", bottom: "70px", color: "white", opacity: `${arrowVisible ? 0.9 : 0}`, animation: `${arrowVisible ? `fade-in` : `fade-out`} 0.5s ${arrowVisible ? `ease-in` : `ease-out`}` }}>
          <path id="XMLID_27_" d="M15,180h263.787l-49.394,49.394c-5.858,5.857-5.858,15.355,0,21.213C232.322,253.535,236.161,255,240,255
	s7.678-1.465,10.606-4.394l75-75c5.858-5.857,5.858-15.355,0-21.213l-75-75c-5.857-5.857-15.355-5.857-21.213,0
	c-5.858,5.857-5.858,15.355,0,21.213L278.787,150H15c-8.284,0-15,6.716-15,15S6.716,180,15,180z"/>
        </svg>
      </div>

      <div style={{
        height: "100%", animation: "fade-in .5s ease-in"
      }}>
        <div className='logo-container'>
          {/* <div className={`background-color home-page ${exiting ? `exiting` : ``}`}></div> */}
          <div className={`image-wrapper ${exiting ? `exiting` : ``}`}>
            {/* className={`mix-artwork ${mixOpening ? `open` : `closed`}`} */}
            <img className="logo" src={Logo} alt="logo" />
          </div>
        </div>
        {/* <div className='info'>
        <h4>Website coming soon.</h4>
        <p>+49 176 6475 3408</p>

        {/* <nav>
        <ul className={`nav-item ${exiting ? `exiting` : ``}`}> */}


        {/* <li className='nav-item'>
            <Link
              to="/about"
              onClick={(e) => delayRoute(e, "/about")}
            >
              About</Link></li>


          <li className='nav-item'>
            <Link
              to={`/book/`}
              onClick={(e) => delayRoute(e, "/book")}
            >Book </Link></li>

          <li className='nav-item'>
            <a
              href="https://mailchi.mp/3254224be5ab/juicy-yoga-sign-up-2"
              target="_blank"
              rel="noreferrer">
              Newsletter</a>
          </li>

          <li className='nav-item'>
            <Link
              to={`/connect/`}
              onClick={(e) => delayRoute(e, "/connect")}
            >Connect </Link></li> */}


        {/* </ul>
        </nav> */}

        <div className='about-container'>
          <div className='about-profile'>
            <div className='image-wrapper'>
              <img className='about-image' src={aboutImage} alt="logo" />
            </div>
            <div className='text-wrapper about-text'>

              <p>
                Juicy Yoga, founded by Delphine Hazledine, aims to support communities by offering a safe and s&nbsp;o&nbsp;o&nbsp;t&nbsp;h&nbsp;i&nbsp;n&nbsp;g space to s&nbsp;l&nbsp;o&nbsp;w down. Through intertwining soundscapes, somatic movement, breathwork, and yoga asana, Delphine's classes provide versatility and often create a m&nbsp;o&nbsp;v&nbsp;i&nbsp;n&nbsp;g meditation.{'\n'}
                {'\n'}
                Delphine has taught extensively in yoga studios, primary schools, universities, in fields at festivals, on permaculture farms and in hotels for corporate events. She has collaborated with renowned brands like Lululemon on public events and facilitated a national recruitment campaign with Ernst & Young, promoting wellbeing in the workplace.{'\n'}
                {'\n'}
                Most recently, Delphine completed multiple trainings in Nuad Bo-Rarn, a traditional form of Northern Thai yoga massage. She incorporates this framework with her degree in Dance and holistic knowledge to offer individual b&nbsp;o&nbsp;d&nbsp;y&nbsp;w&nbsp;o&nbsp;r&nbsp;k sessions.{'\n'}
                {'\n'}
                Delphine currently resides in Berlin, where she enjoys listening to ambient music and jumping into ice-cold lakes!{'\n'}
              </p>

            </div>
          </div>
        </div>


        <div className='contact-container' >

          <div className='text-wrapper'>
            <p>
              Click the links below to c&nbsp;o&nbsp;n&nbsp;n&nbsp;e&nbsp;c&nbsp;t
            </p>
          </div>

          <div className='text-wrapper'>
            {/* <p>
          Please sign up for monthly newsletter for updates <a
            href="https://mailchi.mp/3254224be5ab/juicy-yoga-sign-up-2"
            target="_blank"
            rel="noreferrer">
            HERE.</a>{'\n'}
          {'\n'}
          Or{'\n'}
          {'\n'}
          Message Delphine directly on +49 176 6475 3408 to be added to the Juicy Yoga WhatsApp group{'\n'}
          {'\n'}
          You can also email <a href="mailto:info@juicy.yoga" target="_blank" rel="noopener noreferrer">
            <p className="contact-label">info@juicy.yoga</p>
          </a> for all other inquiries!{'\n'}
        </p> */}

            <div className="insta-contact">


              <a href="https://www.instagram.com/juicy.yoga/" target="_blank" rel="noopener noreferrer">

                <div className='social-icon'>
                  <svg xmlns="http://www.w3.org/2000/svg" width={iconSize} height={iconSize} fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                  </svg>

                </div>
                <p className="contact-label"></p>
              </a>
            </div>

            <div className="whatsapp-contact">
              <a href="https://chat.whatsapp.com/Hs6y4soieHuBZFUnyZyFad" target="_blank" rel="noopener noreferrer">
                <div className='social-icon'>
                  <svg width={iconSize} height={iconSize} fill="currentColor" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 50 50">
                    <path d="M25,2C12.318,2,2,12.318,2,25c0,3.96,1.023,7.854,2.963,11.29L2.037,46.73c-0.096,0.343-0.003,0.711,0.245,0.966 C2.473,47.893,2.733,48,3,48c0.08,0,0.161-0.01,0.24-0.029l10.896-2.699C17.463,47.058,21.21,48,25,48c12.682,0,23-10.318,23-23 S37.682,2,25,2z M36.57,33.116c-0.492,1.362-2.852,2.605-3.986,2.772c-1.018,0.149-2.306,0.213-3.72-0.231 c-0.857-0.27-1.957-0.628-3.366-1.229c-5.923-2.526-9.791-8.415-10.087-8.804C15.116,25.235,13,22.463,13,19.594 s1.525-4.28,2.067-4.864c0.542-0.584,1.181-0.73,1.575-0.73s0.787,0.005,1.132,0.021c0.363,0.018,0.85-0.137,1.329,1.001 c0.492,1.168,1.673,4.037,1.819,4.33c0.148,0.292,0.246,0.633,0.05,1.022c-0.196,0.389-0.294,0.632-0.59,0.973 s-0.62,0.76-0.886,1.022c-0.296,0.291-0.603,0.606-0.259,1.19c0.344,0.584,1.529,2.493,3.285,4.039 c2.255,1.986,4.158,2.602,4.748,2.894c0.59,0.292,0.935,0.243,1.279-0.146c0.344-0.39,1.476-1.703,1.869-2.286 s0.787-0.487,1.329-0.292c0.542,0.194,3.445,1.604,4.035,1.896c0.59,0.292,0.984,0.438,1.132,0.681 C37.062,30.587,37.062,31.755,36.57,33.116z"></path>
                  </svg>
                </div>
                <p className="contact-label"></p>
              </a>
            </div>

            <div className="email-contact">
              <a href="mailto:info@juicy.yoga" target="_blank" rel="noopener noreferrer">
                <div className='social-icon'>
                  <svg xmlns="http://www.w3.org/2000/svg" width={iconSize} height={iconSize} fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                  </svg>
                </div>
                <p className="contact-label"></p>
              </a>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}
export default Home